import revive_payload_client_MBQtj5L1X1 from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.7_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bnrc2r6amrrf75ttwlynwigzyy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4JYBRfY7Bl from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.7_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bnrc2r6amrrf75ttwlynwigzyy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BvvxHp04zw from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.7_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bnrc2r6amrrf75ttwlynwigzyy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_TrMLrE3unG from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@22.8.7_sass@1.69_233toroh2z6ktrmhrvetwb4yli/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_JYOBCoZaQB from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.7_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bnrc2r6amrrf75ttwlynwigzyy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HL2xMbh4tq from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.7_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bnrc2r6amrrf75ttwlynwigzyy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yfbP2tqNfv from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.7_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bnrc2r6amrrf75ttwlynwigzyy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_dhyqAaXuHB from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.7_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bnrc2r6amrrf75ttwlynwigzyy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xjlXZHGH0S from "/var/www/wait-mate-prod/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.3_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/wait-mate-prod/apps/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_PyaBeJVF2f from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.7_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_bnrc2r6amrrf75ttwlynwigzyy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_LGDp8kW1U7 from "/var/www/wait-mate-prod/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node_sm5osb5rq2kwxnzw6xooaw6ft4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import titles_EblFTzUdTj from "/var/www/wait-mate-prod/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.9_h3@1.13.0_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@22.8.7__hgihjnnycrx2xczcy2lu5zpbou/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_n50tyibh5I from "/var/www/wait-mate-prod/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.9_h3@1.13.0_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@22.8.7__hgihjnnycrx2xczcy2lu5zpbou/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_06LD8Er9BE from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@22.8.7_s_ukvy6xvv6ujyhwvwts5svzakhu/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_EqO9vGC27z from "/var/www/wait-mate-prod/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@22.8.7_s_ukvy6xvv6ujyhwvwts5svzakhu/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_j8lBExkD8q from "/var/www/wait-mate-prod/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_@types+react@18.3.12_magicast@0.3.5_react-dom@18.3.1_react@18.3_3cwfcw4yyql64qufe2oofamooi/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import anime_client_02GKJVROBY from "/var/www/wait-mate-prod/apps/nuxt/plugins/anime.client.ts";
import dayjs_pGiXRjcsJO from "/var/www/wait-mate-prod/apps/nuxt/plugins/dayjs.ts";
import maska_UHaKf2z1iQ from "/var/www/wait-mate-prod/apps/nuxt/plugins/maska.ts";
import sentry_client_shVUlIjFLk from "/var/www/wait-mate-prod/apps/nuxt/plugins/sentry.client.ts";
import vue_select_8ji40gexSh from "/var/www/wait-mate-prod/apps/nuxt/plugins/vue-select.ts";
export default [
  revive_payload_client_MBQtj5L1X1,
  unhead_4JYBRfY7Bl,
  router_BvvxHp04zw,
  _0_siteConfig_TrMLrE3unG,
  payload_client_JYOBCoZaQB,
  navigation_repaint_client_HL2xMbh4tq,
  check_outdated_build_client_yfbP2tqNfv,
  chunk_reload_client_dhyqAaXuHB,
  plugin_vue3_xjlXZHGH0S,
  components_plugin_KR1HBZs4kY,
  prefetch_client_PyaBeJVF2f,
  plugin_LGDp8kW1U7,
  titles_EblFTzUdTj,
  defaults_n50tyibh5I,
  siteConfig_06LD8Er9BE,
  inferSeoMetaPlugin_EqO9vGC27z,
  plugin_j8lBExkD8q,
  anime_client_02GKJVROBY,
  dayjs_pGiXRjcsJO,
  maska_UHaKf2z1iQ,
  sentry_client_shVUlIjFLk,
  vue_select_8ji40gexSh
]