import { default as aboutXE3Nv9amWjMeta } from "/var/www/wait-mate-prod/apps/nuxt/pages/about.vue?macro=true";
import { default as indexhn5BN7RUiTMeta } from "/var/www/wait-mate-prod/apps/nuxt/pages/index.vue?macro=true";
import { default as _91slug_930vofG7h9A4Meta } from "/var/www/wait-mate-prod/apps/nuxt/pages/pages/[slug].vue?macro=true";
import { default as pledgewwMhSis5maMeta } from "/var/www/wait-mate-prod/apps/nuxt/pages/pledge.vue?macro=true";
import { default as _91slug_93dCh5kzm33UMeta } from "/var/www/wait-mate-prod/apps/nuxt/pages/resources/[slug].vue?macro=true";
import { default as index7VIL8YipecMeta } from "/var/www/wait-mate-prod/apps/nuxt/pages/resources/index.vue?macro=true";
import { default as unsubscribexNR3X6Wa3HMeta } from "/var/www/wait-mate-prod/apps/nuxt/pages/unsubscribe.vue?macro=true";
import { default as why_45waitTjXtFjx6brMeta } from "/var/www/wait-mate-prod/apps/nuxt/pages/why-wait.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/var/www/wait-mate-prod/apps/nuxt/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/wait-mate-prod/apps/nuxt/pages/index.vue")
  },
  {
    name: "pages-slug",
    path: "/pages/:slug()",
    component: () => import("/var/www/wait-mate-prod/apps/nuxt/pages/pages/[slug].vue")
  },
  {
    name: "pledge",
    path: "/pledge",
    meta: pledgewwMhSis5maMeta || {},
    component: () => import("/var/www/wait-mate-prod/apps/nuxt/pages/pledge.vue")
  },
  {
    name: "resources-slug",
    path: "/resources/:slug()",
    component: () => import("/var/www/wait-mate-prod/apps/nuxt/pages/resources/[slug].vue")
  },
  {
    name: "resources",
    path: "/resources",
    component: () => import("/var/www/wait-mate-prod/apps/nuxt/pages/resources/index.vue")
  },
  {
    name: "unsubscribe",
    path: "/unsubscribe",
    meta: unsubscribexNR3X6Wa3HMeta || {},
    component: () => import("/var/www/wait-mate-prod/apps/nuxt/pages/unsubscribe.vue")
  },
  {
    name: "why-wait",
    path: "/why-wait",
    component: () => import("/var/www/wait-mate-prod/apps/nuxt/pages/why-wait.vue")
  }
]